<template>
  <div class="scrollx">
    <title_cat>Commandes en préparation</title_cat>



    <div class="tuto" v-if="$parent.commandes && $parent.commandes.processing && $parent.commandes.processing.length === 0">
      <img src="../../assets/img/illu/two/processing.svg" alt="">
      <span>
        Vos commandes à préparer apparaîtrons ici, une fois prête, vous n'avez plus qu'a confirmer. Le client est automatiquement informé.
      </span>
    </div>

    <div class="commandes_list" v-else-if="$parent.commandes">
      <div class="commandes_cont">
        
        <commande class="commande" :info_open="true" v-for="n in $parent.commandes.processing" :key="n.id" :data="n" status="processing"></commande>
      </div>
    </div>

    
  </div>
</template>

<script>

import commande from '@/components/bo/commande.vue'
export default {
  name: "commandes_encours",
  components: { commande },
}

</script>